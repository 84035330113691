import React from "react";
import { Link } from "gatsby";
import { maxMedia, MaxWidth, theme } from "../utils/style";
import styled from "styled-components";
import { EasyGrid } from "./styles/EasyGrid";
import { IconButton } from "./styles/LinkButton";
import { FaYoutube, FaLinkedin, FaXing } from "react-icons/fa";
import { SocialLinks } from "../utils/const";

interface Props {}

const Div = styled.div`
  padding: 4rem 0;

  > .copyright {
    margin-top: 2rem;
    text-align: center;
    font-size: 0.75rem;
  }

  > .cols > * {
    flex: 1 1 15%;
  }

  > .cols > .logo {
    flex: 0 10 15%;
  }

  ${maxMedia.phone`
    .cols {
      flex-direction: column;
    }

    .logo {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  `}
`;

export const Footer = (props: Props) => {
  return (
    <MaxWidth style={{ background: theme.primaryColorLight, color: "white" }}>
      <Div>
        <EasyGrid className="cols">
          <EasyGrid vert>
            <p>
              Winfried Neun <br />
              Neun Consulting Group
              <br />
              Postweg 7 <br />
              78256 Steißlingen
              <br />
            </p>
          </EasyGrid>

          <EasyGrid vert>
            <Link className="none" to="/">
              Speakervideo
            </Link>
            <Link className="none" to="/#vortragsreihen">
              Vortragsreihen
            </Link>
            <Link className="none" to="/#philosophie">
              Speaker-Philosophie
            </Link>
            <Link className="none" to="/#referenzen">
              Referenzen
            </Link>
          </EasyGrid>

          <EasyGrid vert>
            <Link className="none" to="/impressum">
              Impressum
            </Link>
            <Link className="none" to="/datenschutz">
              Datenschutz
            </Link>
            <br />

            <a className="none" href="http://verhalten-gestalten.de">
              Verhalten Gestalten
            </a>
            <a className="none" href="http://neunsight.de">
              NEUNsight
            </a>
          </EasyGrid>

          <EasyGrid vert className="logo">
            <img src={require("../assets/logo.svg")} alt="" />
          </EasyGrid>
        </EasyGrid>
        <div className="copyright">Copyright 2021 © - Neun Consulting Group</div>
      </Div>
    </MaxWidth>
  );
};
