import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { maxMedia } from "../utils/style";

export const Modal = (props: any) => {
  const [el, setEl] = React.useState<HTMLDivElement>(null);
  React.useEffect(() => {
    if (!window) {
      return;
    }
    var elem = document.createElement("div");
    elem.classList.add("modal");
    const lastY = window.scrollY;
    document.body.classList.add("modal-on");
    document.body.style.top = `-${lastY}px`;
    document.body.appendChild(elem);
    setEl(elem);

    return () => {
      document.body.classList.remove("modal-on");
      document.body.style.top = "";

      document.body.removeChild(elem);
      window.scrollTo(0, lastY);
    };
  }, [typeof window === "object"]);

  if (!el) {
    return null;
  }

  console.log(props.children);

  return ReactDOM.createPortal(props.children, el);
};

export const ModalInner = styled.div`
  /* background: red; */
  padding: 5rem;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  /* display: flex;
  justify-content: center; */

  .content {
    margin: 0 auto;
    max-width: 800px;
    background: white;
    padding: 3rem;
    padding-bottom: 5rem;

  }
  ${maxMedia.tablet`
    padding: 4rem 2rem 2rem 2rem;
    .content {
      padding: 1rem;
    }
  `}
`;
