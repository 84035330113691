import { Link } from "gatsby";
import { observer, useObservable } from "mobx-react-lite";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import ReactPlayer from "react-player";
import styled, { keyframes } from "styled-components";
import { fillAbs, maxMedia, MaxWidth, minMedia, theme } from "../utils/style";
import { useWindowSize } from "../utils/window-size";
import { EasyGrid } from "./styles/EasyGrid";
import { ThinText } from "./styles/Typography";
import { Modal } from "./Modal";
import { VideoHover } from "./VideoHover";
import { now } from "lodash";
import { MenuHover } from "./MenuHover";

interface Props {
  children?: any;
  background?: string;
}

const HeaderGeneralDiv = styled.div`
  /* min-height: 80vh; */
  position: relative;
  color: white;

  ${MaxWidth} {
    background: url("${p => p.background || require("../images/impressum.jpg")}") ${theme.primaryColor};
    background-size:cover;
    background-position: center center;
    background-blend-mode: multiply;
  }

  ${MaxWidth} .inner {
    min-height: calc(max(300px, 40vh));
  }


  .header-logo {
    position: absolute;
    z-index: 20;
    right: 2rem;
    top: 2rem;

    ${maxMedia.tablet`
      max-width: 225px;
      right: 1rem;
      top: 1rem;
    `}
  }
`;

const menuStructure = [
  {
    title: "Speakervideo",
    to: "/#"
  },
  {
    title: "Vortragsreihen",
    to: "/#vortragsreihen"
  },
  {
    title: "Speaker-Philosophie",
    to: "/#philosophie"
  },
  {
    title: "Referenzen",
    to: "/#referenzen"
  },
  {
    title: "Kontakt",
    to: "/#kontakt"
  }
];

export const HeaderGeneral = observer(({...props}: Props) => {
  let size = useWindowSize();
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
  const store = useObservable({
    showVideo: false
  });

  return (
    <HeaderGeneralDiv {...props}>
      <MaxWidth greyGradient>
        <Link to="/">
          <img width={200} src={require("../assets/logo-speaker.png")} className="header-logo" />
        </Link>
        <EasyGrid vert style={{ height: "100%" }}>
          <MenuSection />
          <div style={{ flexGrow: 1 }} />
          <div style={{ marginRight: "5rem" }}>{props.children}</div>
        </EasyGrid>
      </MaxWidth>
    </HeaderGeneralDiv>
  );
});

const HeaderIndexDiv = styled.div`
  /* min-height: 80vh; */
  position: relative;

  ${MaxWidth} .inner {
    ${minMedia.tablet`
    height: ${p => (p.small ? "60vh" : "80vh")};
    min-height: 600px;
    max-height: 800px;
    `}
  }

  .header-bg {
    ${fillAbs};
    z-index: 0;
    background-image: url(${require("../images/trapez-bg.svg")});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${maxMedia.tablet`
      background: ${theme.primaryColor};
      background-position: 100% center;

      &:before {
        content: url("data:image/svg+xml; utf8, ${encodeURIComponent(`<svg width="109" height="226" viewBox="0 0 109 226" xmlns="http://www.w3.org/2000/svg">
<path d="M107.5 0.5H0.5V225.5L107.5 0.5Z" fill="white"/>
</svg>
`)}")
        
      }
    `}
  }

  .header-logo {
    position: absolute;
    z-index: 20;
    right: 2rem;
    top: 2rem;

    ${maxMedia.tablet`
      max-width: 225px;
      right: 1rem;
      top: 1rem;
    `}
  }

  .wn-standing {
    position: absolute;
    z-index: 20;
    left: 35%;
    bottom: 0rem;
    margin: 0;
    width: 600px;
    max-width: 70%;

    ${maxMedia.tablet`
      position: static;
      margin-left: 2rem;
    `}
  }

  .text-column {
    ${minMedia.tablet`
      position: absolute;
      left: 0;
      max-width: calc(max(600px, 40%));
      top: 4rem;
      height: calc(100% - 4rem);
      padding: 1rem;
    `}

    ${maxMedia.tablet`
      position: static;
      padding: 1rem 1rem;
      background: black;
      color: white;
      margin: -0.4rem -1rem -1rem -1rem;
      position: relative;

      &:before {
        content: "";
        ${fillAbs};
        height: 180px;
        top: -180px;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, black 100%);
      }

      a {
        color: white !important;
      }
    `}


    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .video-thumb {
    ${maxMedia.tablet`
      width: 100%;
      max-width: 500px;
    `}
  }
`;

export const HeaderIndex = observer((props: Props) => {
  let size = useWindowSize();
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
  const store = useObservable({
    showVideo: false
  });

  return (
    <HeaderIndexDiv {...props} expanded={mobileMenuExpanded}>
      {store.showVideo && (
        <VideoHover
          onClose={() => {
            store.showVideo = false;
          }}
        >
          <ReactPlayer
            width={size.width * 0.8}
            height={(size.width * 0.8) / 1.77777777778}
            playing
            controls
            url={require("../assets/output3.mp4")}
          />
        </VideoHover>
      )}

      <div className="header-bg" />
      <MaxWidth greyGradient>
        <Link to="/">
          <img src={require("../assets/logo-speaker.png")} className="header-logo" />
        </Link>

        <MenuSection light />

        <img src={require("../assets/wn-cutout-small.png")} className="wn-standing" />

        <div className="text-column">
          <div className="content">
            <h3>Wirtschaft &amp; Psychologie</h3>
            <TextCircus />
            <ThinText>Keynote-Speaker - Winfried Neun</ThinText>
            <EasyGrid vert style={{ marginTop: "3rem" }}>
              <div className="title-video">
                <b>
                  "The Voice of Change" -
                </b>{" "}
                <br />
                Jetzt Trailer anschauen
              </div>

              <FloatyImg
                className="video-thumb"
                onClick={() => (store.showVideo = true)}
                width="320"
                src={require("../assets/video-placeholder.jpg")}
              />

              <div style={{ marginTop: "1rem" }}>
                <a href="#vortragsreihen">→ Weitere Vortragsreihen</a>
              </div>
              <div style={{ whiteSpace: "nowrap" }}>
                <a href="#kontakt">→ Kontakt aufnehmen</a>
              </div>
            </EasyGrid>
          </div>
        </div>
      </MaxWidth>
    </HeaderIndexDiv>
  );
});

const FloatyImg = styled.img`
  cursor: pointer;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  transform: translatey(0px);

  &:hover {
    box-shadow: 0 7px 15px 0px rgba(0, 0, 0, 0.5);
    transform: translatey(-3px);
  }
  transition: all 0.4s;
`;

const TEXTS = ["Leidenschaftlich", "Kompetent", "Visionär"];

const TextCircus = (props: any) => {
  const [index, setIndex] = React.useState(0);
  const TextTransition = typeof window === "object" ? require("react-text-transition").default : {};

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearInterval(intervalId);
  });
  return (
    <h2 style={{ fontSize: "2.5rem" }}>
      {typeof window === "object" && <TextTransition text={TEXTS[index % TEXTS.length]} />}
    </h2>
  );
};

const Menu = styled.div`
  color: white;
  font-size: 1rem;
  max-height: 100vh;

  > .mobile-menu-content {
    padding: 5rem 0;
    position: relative;
    z-index: 2;
    color: white;
    padding: 3rem;
    display: flex;
    flex-direction: column;

    > *:first-child {
      margin-top: 10vh;
    }

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }

    > .inner-item {
      /* font-size: 0.8rem; */
      font-weight: 100;
      margin-left: 1rem;
    }
  }
`;

const MenuSectionDiv = styled.div`
  ${maxMedia.tablet`
    .contact-cta {
      display: none;
    }
  `}
`;

const MenuSection = observer((props: { light?: boolean }) => {
  const setMobileMenuExpanded = (newVal: boolean) => {
    store.showMenu = newVal;
  };
  const store = useObservable({ showMenu: false });

  return (
    <React.Fragment>
      <MenuSectionDiv>
        <EasyGrid style={{ alignItems: "center", flexWrap: "nowrap" }}>
          <FaBars style={{ cursor: "pointer" }} size={"2rem"} onClick={() => (store.showMenu = !store.showMenu)} />
          <div className="contact-cta" style={{ marginLeft: "1rem", whiteSpace: "nowrap" }}>
            <Link className="inherit" to="/#kontakt">
              → Kontakt aufnehmen
            </Link>
          </div>
        </EasyGrid>
      </MenuSectionDiv>
      {store.showMenu && (
        <Modal>
          <MenuHover
            onClose={() => {
              store.showMenu = false;
            }}
            className="menu"
          >
            <Menu>
              <div className="mobile-menu-content">
                {menuStructure.map(x => (
                  <>
                    <Link key={x.to} to={x.to} className="menu-item" onClick={() => setMobileMenuExpanded(false)}>
                      › {x.title}
                    </Link>

                    {(x.children || []).map(y => (
                      <Link className="inner-item" to={y.to} onClick={() => setMobileMenuExpanded(false)}>
                        › {y.title}
                      </Link>
                    ))}
                  </>
                ))}
              </div>
            </Menu>
          </MenuHover>
        </Modal>
      )}
    </React.Fragment>
  );
});
