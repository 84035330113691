import * as React from "react";
import { WavePlayer } from "./aktueller-podcast";
import { uiRegistry } from "../utils/ui-registry";
import { Footer } from "./footer";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import { MaxWidth, maxMedia, theme } from "../utils/style";

export const Layout = React.forwardRef(({ children, ...props }: any, ref: any) => {
  React.useEffect(() => {
    const isIE11 = eval("!!window.MSInputMethodContext && !!document.documentMode");
    if (isIE11) return;
    // window.dispatchEvent(new Event("resize"));
  });

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "cmc.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <GlobalWrap {...props} ref={ref}>
      {children}

      <CMC>
        <p>Member of:</p>
        <a href="https://germanspeakers.org/" target="_blank">
          <img src={require("../assets/gsa.png")}></img>
        </a>
      </CMC>

      <Footer />
    </GlobalWrap>
  );
});

const GlobalWrap = styled.div`
  a {
    font-weight: bold;
    &:not(.none) {
      color: ${theme.primaryColor};
    }

    &.white {
      color: white;
    }

    &.inherit {
      color: inherit;
    }
  }
`;

const CMC = styled.div`
  position: fixed;
  right: 0;
  bottom: 0rem;
  width: 300px;
  background: white;
  padding: 0.5rem 1rem;
  z-index: 1;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);

  p {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  ${maxMedia.phone`
    p {
      font-size: 0.5rem;
    }

    padding: 0.25rem 0.5rem;
    position: absolute;
    width: 180px;
    bottom: auto;
    top: 270px;
  `}
`;
