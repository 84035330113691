import React from "react";
import { FaTimes } from "react-icons/fa";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const VideoHoverDiv = styled.div`
  position: fixed;
  left: 0;
  width: 100vw;
  top: 0;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  animation: ${fadeIn} 0.4s linear;
  overscroll-behavior: contain;

  .closer {
    position: fixed;
    color: white;
    top: 1rem;
    right: 2rem;
    font-size: 2rem;
    cursor: pointer;
    z-index: 100;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const VideoHover = ({
  onClose,
  children,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  onClose: any;
  children: any;
}) => {
  return (
    <VideoHoverDiv className="outer" {...rest}>
      <div onClick={onClose} className="closer">
        <FaTimes />
      </div>
      {children}
    </VideoHoverDiv>
  );
};
